import type { GetServerSideProps } from 'next'
import { Login } from '../components/Login'
import type { IncomingMessage } from 'http'
import { z } from 'zod'
import { getLogger } from '../lib/logger'

type Props = {
  redirect: string
}

export const getServerSideProps = (async params => {
  const r = getRedirectUrl(params.req)
  return { props: { redirect: r } }
}) satisfies GetServerSideProps<Props>

export default function LoginPage(props: Props) {
  return <Login redirect={props.redirect} />
}

const DEFAULT_REDIRECT = '/'
const RedirectableRequestSchema = z.object({
  url: z.string(),
  headers: z.object({
    host: z.string(),
    'x-forwarded-proto': z.string(),
  }),
})
function getRedirectUrl(req: IncomingMessage): string {
  const parsedRequest = RedirectableRequestSchema.safeParse(req)
  if (!parsedRequest.success) {
    return DEFAULT_REDIRECT
  }

  const base = `${parsedRequest.data.headers['x-forwarded-proto']}://${parsedRequest.data.headers.host}`
  try {
    const requestUrl = new URL(parsedRequest.data.url, base)
    const redirectSearchParam = requestUrl.searchParams.get('redirect')
    if (!redirectSearchParam) {
      return DEFAULT_REDIRECT
    }

    return redirectSearchParam
  } catch (e) {
    getLogger().error(e)
    return DEFAULT_REDIRECT
  }
}
